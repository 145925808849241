.details_header {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.details_header_container {
  width: 60%;
}
.details_header > img {
  height: 10rem;
  border-radius: 2rem;
  object-fit: contain;
  aspect-ratio: 1;
}
.details_header > div > h1 {
  font-size: 2.5rem;
}
.details_header > div > div {
  gap: 1rem;
  display: flex;
}
.rating_box {
  display: flex;
  align-items: center;
  gap: 0.5rem !important;
}
.rating_box > img {
  height: 1rem;
  object-fit: contain;
}
.details_synopsis {
  margin-top: 1rem;
}
.cast_screens {
  display: flex;
  margin-top: 1rem;
  gap: 2rem;
}
.video_container {
  width: 100%;
}
.video_container > video {
  width: 100%;
  border-radius: 1rem;
  /* object-fit: contain; */
}
.watch_trailer_btn {
  display: inline-flex;
  gap: 1rem;
  flex-wrap: wrap;

  align-items: flex-start;
}
.watch_trailer_btn > button {
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center !important;
  justify-content: center;
}
.watch_trailer_btn > button > svg path {
  fill: var(--text-color-2);
  transition: 200ms ease-in;
}
.watch_trailer_btn > button > svg {
  width: 2rem;
}
.watch_trailer_btn > button {
  margin-top: 1rem;
  display: flex;
  border: 2px solid var(--text-color-2);
  font-weight: 500;
  font-size: 1.3rem;
  width: fit-content;
  color: var(--text-color-2);
  align-items: flex-start;
  background-color: transparent;
  border-radius: 1rem;
  transition: 200ms ease-in;
}
.trailers_btns {
  display: flex;
  background: var(--nav-color);
}
.trailers_btns > button {
  margin-top: 0rem !important;
}
.watch_trailer_btn > button:hover {
  color: var(--white-color);
  border: 2px solid var(--white-color);
}
.disabled_btn {
  border: 2px solid var(--disabled-btn) !important;
  color: var(--disabled-btn) !important;
  cursor: not-allowed !important;
}
.watch_trailer_btn > button:hover > svg path {
  fill: var(--white-color);
}

.watch_trailer_btn > button:active {
  color: var(--text-color-2);
  border: 2px solid var(--text-color-2);
}
.watch_trailer_btn > button:active > svg path {
  fill: var(--text-color-2);
}
.video_container_header {
  background: var(--nav-color);
  display: flex;
  align-items: center;
}
.trailer_duration {
  color: var(--text-color-2);
  font-weight: 500;
}

.trailer_disabled_btn {
  cursor: not-allowed !important;
  opacity: 0.5;
}

/* media queries for mobile screen */

@media screen and (min-width: 270px) and (max-width: 480px) {
  .details_header_container {
    width: 100%;
  }
  .details_header > img {
    height: 5rem;
  }
  .details_header > div > h1 {
    font-size: 1.5rem;
  }
  .details_header > div > div > h4 {
    font-size: 1rem;
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 280px) and (max-width: 620px) {
  .watch_trailer_btn {
    /* flex-direction: column; */
    /* gap: 0; */
  }
  .watch_trailer_btn > button {
    padding: 0.2rem;
  }
  .trailers_btns {
    flex-direction: row;
    /* gap: 1rem; */
  }
}

@media screen and (min-width: 480px) and (max-width: 867px) {
}
