.details_container {
  width: 100%;
  max-height: 94vh;
  background-size: cover;
  position: relative; /* Sets up a relative positioning context */
  z-index: 1; /* Ensures this container stays above other background layers if needed */
}


.details_container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px; /* Gradient overlay height */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.856), transparent); /* Gradient */
  z-index: -1; /* Places the overlay behind content inside .details_container */
}

.dark_overlay {
  padding: 2rem 2rem 0rem 2rem;
  background: linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0.5));
  width: 100%;
  min-height: 94vh;
}
