.rating_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 4rem;
  gap: 1rem;
  width: 60%;
}
.rating_container > h2 {
  text-align: center;
}
/* .rating_container > h3 {
  width: 50%;
  margin: auto;
  text-align: center;
} */
.star_container > svg:hover {
  transform: scale(1.1);
}
.star_container > svg {
  width: 4rem;
  cursor: pointer;
  transition: 100ms ease-in;
}
.rating_container > textarea {
  width: 100%;
  background-color: var(--input-color);
  border: none;
  color: var(--text-color-2);
  font-size: 1rem;
}
.rating_container > textarea:focus {
  background-color: #3e404a; /* Change background color on focus */
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.164); /* Add a subtle box shadow on focus */
  outline: none; /* Remove the default focus outline */
}
.follow_btn {
  border: 1px solid var(--text-color-2);
  color: var(--text-color-2);
  background: transparent;
  font-size: 1.4rem;
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
}

.follow_btn:hover {
  color: var(--white-color);
  border: 1px solid;
  transition: 200ms ease-in;
}
.follow_btn:active {
  border: 1px solid var(--text-color-2);
  color: var(--text-color-2);
}

@media screen and (min-width: 270px) and (max-width: 480px) {
  .rating_container {
    width: 90%;
  }
  .star_container > svg {
    width: 3rem;
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
  .rating_container {
    width: 80%;
  }
}

@media screen and (min-width: 480px) and (max-width: 867px) {
}
