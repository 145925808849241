.order_history_container {
  width: 90%;
  margin: 0 auto;
  margin-top: 2rem;
}
.order_history_container > h4 {
  color: var(--text-color-2);
}
.header_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}
.header_container > p {
  font-size: 1.3rem;
}
.header_container > p span {
  color: #ffaf22;
  font-weight: 500;
}
.header_container > div {
  display: flex;
  gap: 0.5rem;
}
.header_container > div > button {
  background: var(--text-color-2);
  border-radius: 0.3rem;
  padding: 0.4rem 0.5rem;
  font-weight: 500;
  display: flex;
  border: none;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}
.historys {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--text-color-2);
}
.history_header,
.historys > div {
  display: flex;
  align-items: center;
}
.history_header {
  border-bottom: 2px solid var(--text-color-2);
  margin-top: 1.5rem;
}

.history_header > div,
.historys > div > div {
  flex: 1;
  text-align: center;
  font-weight: 500;
  font-size: 1.3rem;
}
.order_history_posters > img {
  height: 60px;
  aspect-ratio: 2/1;
  object-fit: contain;
  border-radius: 1rem;
}
.order_history_items {
  transition: 400ms ease-in;
  padding-top: 1rem;
  border-radius: 1rem;
  cursor: pointer;
}
.order_history_items:hover {
  background: var(--nav-color);
}
.ticket_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.ticket_container > div > img:nth-child(2) {
  position: absolute;
  transform: rotate(-10deg);
  bottom: 0.5rem;
}

.ticket_container > div {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 0.5rem;
}
.pagination_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  font-weight: 500;
  margin-top: 2rem;
  transition: 400ms ease-in;
  margin-bottom: 2rem;
}
.pagination_container > div {
  cursor: pointer;
  border-bottom: 2px solid var(--bg-color);
  color: var(--white-color);
}
.pagination_container svg {
  fill: var(--white-color);
  cursor: pointer;
}
.pagination_container svg:hover {
  fill: var(--text-color-2);
}
.pagination_container > button {
  background: none;
  border: none;
}
.pagination_container svg:active {
  fill: var(--white-color);
}
.activePage {
  color: var(--text-color-2) !important;
  border-bottom: 2px solid var(--text-color-2) !important;
}

.history_transfer_type_video_purchase {
  /* border: 1px solid var(--text-color-2) !important; */
  color: var(--text-color-2) !important;
}
.history_transfer_type_video_bonus {
  /* border: 1px solid var(--text-color-2) !important; */
  color: #ffaf22 !important;
}
.history_transfer_type_video_recharge {
  /* border: 1px solid var(--text-color-2) !important; */
  color: #11cd59 !important;
}

/* media queries for mobile screen */

@media screen and (min-width: 270px) and (max-width: 480px) {
  .historys > div > div {
    font-size: 1rem;
  }
  .order_history_posters > img {
    width: 100%;

    height: 50px;
    object-fit: contain;
  }
  .order_history_items {
    padding: 0.5rem;
  }
  .ticket_container > div > img {
    height: 10px;
    object-fit: contain;
  }

  .history_transfer_type_video_purchase {
    font-size: 0.5rem !important;
    /* border: 1px solid var(--text-color-2) !important; */
    color: var(--text-color-2) !important;
  }
  .history_transfer_type_video_bonus {
    font-size: 0.5rem !important;
    /* border: 1px solid var(--text-color-2) !important; */
    color: #ffaf22 !important;
  }
  .history_transfer_type_video_recharge {
    font-size: 0.5rem !important;
    /* border: 1px solid var(--text-color-2) !important; */
    color: #11cd59 !important;
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
}

@media screen and (min-width: 480px) and (max-width: 867px) {
}
