#full-stars-example .rating-group {
  display: inline-flex;
}

#full-stars-example .rating__icon {
  pointer-events: none;
}

#full-stars-example .rating__input {
  position: absolute !important;
  left: -9999px !important;
}

#full-stars-example .rating__label {
  cursor: pointer;
  padding: 0 0.1em;
  font-size: 4rem !important;
}

#full-stars-example .rating__icon--star {
  color: orange;
}

#full-stars-example .rating__icon--none {
  color: #eee;
}

#full-stars-example
  .rating__input--none:checked
  + .rating__label
  .rating__icon--none {
  color: red;
}

#full-stars-example
  .rating__input:checked
  ~ .rating__label
  .rating__icon--star {
  color: #ddd;
}

#full-stars-example .rating-group:hover .rating__label .rating__icon--star {
  color: orange;
}

#full-stars-example .rating__input:hover ~ .rating__label .rating__icon--star {
  color: #ddd;
}

#full-stars-example
  .rating-group:hover
  .rating__input--none:not(:hover)
  + .rating__label
  .rating__icon--none {
  color: #eee;
}

#full-stars-example
  .rating__input--none:hover
  + .rating__label
  .rating__icon--none {
  color: red;
}

#half-stars-example .rating-group {
  display: inline-flex;
}

#half-stars-example .rating__icon {
  pointer-events: none;
}

#half-stars-example .rating__input {
  position: absolute !important;
  left: -9999px !important;
}

#half-stars-example .rating__label {
  cursor: pointer;
  padding: 0 0.1em;
  font-size: 4rem;
}

#half-stars-example .rating__label--half {
  padding-right: 0;
  margin-right: -1.2em;
  z-index: 2;
}

#half-stars-example .rating__icon--star {
  color: orange;
}

#half-stars-example .rating__icon--none {
  color: #eee;
}

#half-stars-example
  .rating__input--none:checked
  + .rating__label
  .rating__icon--none {
  color: red;
}

#half-stars-example
  .rating__input:checked
  ~ .rating__label
  .rating__icon--star {
  color: #ddd;
}

#half-stars-example .rating-group:hover .rating__label .rating__icon--star,
#half-stars-example
  .rating-group:hover
  .rating__label--half
  .rating__icon--star {
  color: orange;
}

#half-stars-example .rating__input:hover ~ .rating__label .rating__icon--star,
#half-stars-example
  .rating__input:hover
  ~ .rating__label--half
  .rating__icon--star {
  color: #ddd;
}

#half-stars-example
  .rating-group:hover
  .rating__input--none:not(:hover)
  + .rating__label
  .rating__icon--none {
  color: #eee;
}

#half-stars-example
  .rating__input--none:hover
  + .rating__label
  .rating__icon--none {
  color: red;
}

#full-stars-example-two .rating-group {
  display: inline-flex;
}

#full-stars-example-two .rating__icon {
  pointer-events: none;
}

#full-stars-example-two .rating__input {
  position: absolute !important;
  left: -9999px !important;
}

#full-stars-example-two .rating__input--none {
  display: none;
}

#full-stars-example-two .rating__label {
  cursor: pointer;
  padding: 0 0.1em;
  font-size: 2rem;
}

#full-stars-example-two .rating__icon--star {
  color: orange;
}

#full-stars-example-two
  .rating__input:checked
  ~ .rating__label
  .rating__icon--star {
  color: #ddd;
}

#full-stars-example-two .rating-group:hover .rating__label .rating__icon--star {
  color: orange;
}

#full-stars-example-two
  .rating__input:hover
  ~ .rating__label
  .rating__icon--star {
  color: #ddd;
}
.star_conatiner {
  border: 1px solid;
  margin-right: 2rem;
}
.rating__label {
  /* border: 1px solid; */
  margin-right: -0.1rem;
}
