.pricing_box {
  border: 1px solid var(--text-color-2);
  display: inline-block;
  border-radius: 0.5rem;
  text-align: center;
  margin-top: 1.5rem;
}
.pricing_box > h5 {
  color: var(--text-color-2);
  margin-top: 0.5rem;
}
.pricing_box > div {
  display: flex;
  border-top: 1px solid var(--text-color-2);
}
.pricing_box > div > div:nth-child(1) {
  border-right: 1px solid var(--text-color-2);
  border-bottom-left-radius: 0.5rem;
}
.pricing_box > div > div {
  display: flex;
  flex-direction: column;
}
.pricing_box > div > div:nth-child(2) {
  border-bottom-right-radius: 0.5rem;
}
.pricing_box > div > div {
  padding: 0.5rem 2rem;
  cursor: pointer;
  font-weight: 500;
}
.pricing_text {
  display: flex;
  width: 100%;
  font-size: 0.8rem;
  align-items: baseline;
}
.pricing_text > span {
  font-size: large;
}
.selected_pricing {
  background-color: var(--text-color-2);
  color: #000;
}
.price_helper_text {
  margin-top: 0.5rem;
}

.watch_free_box {
  display: flex;
  width: 50%;
  margin-top: 1rem;
  align-items: flex-start;
}
.watch_btn,
.wishlist_btn {
  padding: 0.4rem 3rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: 200ms ease-in;
  background: transparent;
}
.wishlist_btn {
  border: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--white-color);
}
.color_green {
  color: var(--text-color-2);
}
.watch_btn {
  border: 1px solid var(--text-color-2);
  color: var(--text-color-2);
}
.disabled_btn {
  border: 1px solid var(--disabled-btn);
  color: var(--disabled-btn);
  cursor: not-allowed;
}
.wishlist_btn:hover {
  transform: scale(1.05);
}
.wishlist_btn:active {
  color: var(--text-color-2);
}
.watch_free_box > div {
  display: flex;
  flex-direction: column;
}
.creators_folow_box {
  margin-top: 1rem;
}
.creators_folow_box > div {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.payment_btns {
  background-color: var(--text-color-2);
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
  border: 1px solid var(--text-color-2);
  color: var(--white-color);
}
.payment_btns:hover {
  background: transparent;
}

.payment_btns:active {
  background-color: var(--text-color-2);
}
.follow_btn {
  border: 1px solid var(--text-color-2);
  color: var(--text-color-2);
  background: transparent;
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
}

.follow_btn:hover {
  color: var(--white-color);
  border: 1px solid;
  transition: 200ms ease-in;
}
.follow_btn:active {
  border: 1px solid var(--text-color-2);
  color: var(--text-color-2);
}

.price_modal_container {
  background: var(--bg-color-2);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  border-bottom: 0px;
}
.price_modal_footer {
  background: var(--bg-color-2);
}
.disabled_box {
  opacity: 0.4;
  cursor: not-allowed !important;
}

.resume_btn_box {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}
.follow_btn,
.resume_btn {
  border: 1px solid var(--text-color-2);
  color: var(--text-color-2);
  background: transparent;
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
}
.resume_btn {
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 1.3rem;
}
.resume_btn_box > button:nth-child(2) {
  color: var(--text-color-2);
  color: var(--text-color-2);
  background: transparent;
  border: none;
  padding: 0.1rem 0.5rem;
  font-size: 1.3rem;
}
.resume_btn path {
  fill: var(--text-color-2);
  transition: 200ms ease-in;
}
.follow_btn:hover,
.resume_btn:hover {
  color: var(--white-color);
  border: 1px solid;
  transition: 200ms ease-in;
}
.resume_btn:hover path {
  fill: var(--white-color);
}
.resume_btn_box > button:nth-child(2):hover {
  color: var(--white-color);

  transition: 200ms ease-in;
}
.resume_btn_box > button:nth-child(2):active {
  color: var(--text-color-2);
}

.disabled_box {
  position: relative;

  overflow: hidden; /* Ensure the pseudo-element is contained within the div */
}

.disabled_box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 141.42%; /* 141.42% is approximately sqrt(2) * 100% */
  height: 4px; /* Thickness of the diagonal line */
  background-color: #f0f0f0; /* Color of the diagonal line */
  transform: rotate(19deg); /* Rotate to make the line diagonal */
  transform-origin: 0 0; /* Ensure the rotation pivot is at the top-left corner */
}
/* media queries for mobile screen */

@media screen and (min-width: 270px) and (max-width: 480px) {
  .pricing_box > div > div {
    padding: 0.2rem;
  }
  .watch_btn,
  .wishlist_btn {
    width: 100%;
    padding: 0.3rem;
  }
  .watch_free_box {
    display: flex;
    width: 100%;
    gap: 1rem;
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
}

@media screen and (min-width: 480px) and (max-width: 867px) {
}
