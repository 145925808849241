.tickets_container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.ticketCategories {
  border: 1px solid var(--text-color-2);
  padding: 0.4rem 1rem;
  cursor: pointer;
  transition: all 3ms ease-in-out;
}

.selectedCatgory {
  background: var(--text-color-2);
  color: var(--bg-color-1);
}
.support_container {
  padding: 2rem;
}

.support_container > div {
  width: 60%;
  margin: auto;
  margin-top: 2rem;
}
.support_form > form > h4 {
  text-align: center;
}
.support_form > form {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: auto;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border: 1px solid var(--text-color-2);
}
.form_items > h6 {
  font-size: 1.2rem;
}
.form_items > input,
.form_items > textarea {
  width: 100%;
  font-size: 1.3rem;
  padding: 0.3rem 1rem;
  background: var(--nav-color);
  background-color: var(--input-color);
  border: none;
  border-radius: 0;
  color: var(--white-color);
}
.form_items > input:focus,
.form_items > textarea:focus {
  background-color: #3e404a; /* Change background color on focus */
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.164); /* Add a subtle box shadow on focus */
  outline: none; /* Remove the default focus outline */
}

.support_form > form > div > button,
.support_btn {
  margin-top: 1rem;
  padding: 0.3 1rem;
  font-weight: 500;
  font-size: 1.3rem;
  background-color: var(--text-color-2);
  border: 1px solid var(--text-color-2);
  color: var(--white-color);
}
.support_btn:hover {
  background: transparent;
}
.support_btn:active {
  background-color: var(--text-color-2);
}

.support_btn_container {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 270px) and (max-width: 480px) {
  .support_form > form {
    width: 100%;
    padding: 1rem;
  }
  .support_container > div {
    width: 100%;
  }
  .form_items > input,
  .form_items > textarea {
    font-size: 1rem;
  }
  .form_items > h6 {
    font-size: 1rem;
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
  .support_form > form {
    width: 80%;
    padding: 1rem;
  }
  .support_container > div {
    width: 100%;
  }
  .form_items > input,
  .form_items > textarea {
    font-size: 1rem;
  }
  .form_items > h6 {
    font-size: 1rem;
  }
}

@media screen and (min-width: 480px) and (max-width: 1030px) {
}
