.login_container {
  background: var(--bg-color-1);
  min-height: 100vh;
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom; */
}

.login_box {
  width: 50%;
  margin: auto;
  text-align: center;
  padding-top: 60px;
}
.login_img_box div {
  color: var(--white-color);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.login_form_box {
  margin-top: 40px;
}
.login_form_box > form {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.login_form_box > form input {
  color: var(--text-color-1);
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--white-color);

  line-height: normal;
}
.forgot_container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.forgot_pass {
  cursor: pointer;
  color: var(--white-color);
}
.forgot_pass:hover {
  text-decoration: underline;
}

/* Style the input element on focus */
.login_form_box > form input:focus {
  border: 1px solid var(--btn-color-1); /* Change border color on focus */
  outline: none; /* Remove default focus outline (optional) */
}

/* Style the placeholder text */
.login_form_box > form input::placeholder {
  color: var(--text-color-1); /* Set the placeholder text color */
}
.login_form_box > form > button:hover {
  background-color: var(--text-color-1);
}
.login_form_btn {
  width: 100%;
}
.login_form_btn > button {
  background-color: var(--btn-color-1);
  color: var(--white-color);
  border-radius: 10px;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  padding: 10px 15px;
  line-height: normal;
  border: none;
  /* margin-top: 30px; */
}

.continue_with_google {
  margin-top: 20px;
}
.continue_with_google > button:hover {
  background-color: var(--btn-color-1);
  color: var(--white-color);
  /* background-color: var(--text-color-1); */
}
.continue_with_google > button {
  border: none;
  color: var(--text-color-1);
  font-family: "Noto Sans", sans-serif;
  background: var(--white-color);
  font-size: 17px;
  font-style: normal;
  border-radius: 10px;
  padding: 10px 25px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 10px;
  margin: auto;
}
.continue_with_google > div {
  color: var(--white-color);
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  line-height: normal;
}
.continue_with_google > div > span:hover {
  color: var(--white-color);
}
.continue_with_google > div > span {
  color: var(--text-color-1);
  font-weight: 500;
  cursor: pointer;
}

.continue_with_google > .login_help_text {
  font-size: 16px;
}
.continue_with_google > .login_help_text span a:hover {
  color: var(--text-color-1);
}
.login_help_text span a {
  font-weight: 500;
  color: var(--white-color);
  text-decoration: underline !important;
}
.continue_with_google > .login_help_text span a {
  font-weight: 500;
  color: var(--white-color);
}
.login_text_forgot {
  color: var(--white-color);
  text-align: end;
}

.input_group {
  position: relative;
  width: 100%;
}
.input_group_password_icon {
  position: absolute;
  color: var(--text-color-2);
  right: 10px;
  top: 50%; /* Center the icon vertically */
  transform: translateY(-50%);
  text-decoration: none;
}
/* media queries for mobile screen */

@media screen and (min-width: 270px) and (max-width: 480px) {
  .login_box {
    width: 100%;
  }
  .login_container {
    width: 100%;
    /* padding: 10px; */
  }
  .login_form_box > form input {
    font-size: 15px;
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
}

@media screen and (min-width: 480px) and (max-width: 867px) {
  .login_box {
    width: 95%;
  }
  .login_container {
    width: 100%;
  }
  .login_form_box > form input {
    font-size: 15px;
  }
}
