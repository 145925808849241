@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500;600&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-size: 16px;
  font-family: "Noto Sans", sans-serif;
  background: var(--bg-color-2) !important;
  color: var(--white-color) !important;
}
:root {
  --text-color-1: #003583;
  --text-color-2: #017bf4;
  --bg-color-1: linear-gradient(118deg, #003583 0%, #52e5c2 97.25%);
  --btn-color-1: #1b47e4;
  --white-color: #fff;
  --nav-color: #252730;
  --input-color: #3e404a;
  --bg-color-2: #111217;
  --disabled-btn: rgba(82, 229, 194, 0.41);
  --bg-color-3: var(--Black-08, #141414);
}
a {
  text-decoration: none !important;
}

nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  /* width: 300px; */
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: var(--nav-color);
  z-index: 3;
}

.ul,
.li {
  margin: 0;
  padding: 0;
}

.ul {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
}

.li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
