.payment_error {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.payment_error > svg {
  width: 300px;
  margin: auto;
}
