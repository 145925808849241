.toast {
  position: absolute;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 1;
  top: 30px;
  z-index: 999;
  justify-content: space-between;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s ease-in-out;
}

.show {
  opacity: 1;
}

.fade-in {
  animation: fade-in 0.3s;
}

.toast-message {
  font-size: 16px;
}
.form-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  margin: auto;
  max-width: 450px;
  padding: 60px 68px 40px;
  margin-bottom: 100px;
  z-index: 1;
}

.card {
  min-width: 360px;
  padding: 2rem;
  box-shadow: 0 1px 3px #d4d4d5, 0 0 0 1px #d4d4d5;
  border-radius: 4px;
  background: white}

.logout-card {
  border-radius: 10px;
  min-height: 40px;
  font-size: 14px;
  line-height: 21px;
}

.common_box {
  box-shadow: 0px 0px 15px #E4E4E4B8;
  border-radius: 20px;
  width: 100%;
  margin: auto;
}

.common_box_body {
  padding: 45px 35px 35px;
}

.custom_form{
  margin-bottom: 20px;
}

.btn-wrapper {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}

.card{border: 0px;}

.card {
    content: "\f107";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    float: right;
  }
.card {
    content: "\f106";
}

.fw-bold{
  font-size: 23px;
}

.round-modal-btn{
  background: rgb(30,122,185);
  font-size: 20px;
  color: #ffffff;
  border: 0px;
  text-transform:uppercase;
  font-weight: 500;
  border-radius: 30px;
  padding: 10px 50px;
  text-align: center;
  margin: 0px auto;
  cursor: pointer;
}

.round-modal-btn:hover{color: black;}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* media queries for small screens  */

@media (max-width: 400px) {
  .toast {
    width: 80% !important;
    margin: auto;
  }
}
