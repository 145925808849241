.top_navbar_container {
  background: var(--bg-color-3);
  display: flex;
  align-items: center;
  padding: 0.5rem;
}
.navbar_wrapper {
  display: flex;
  align-items: center;
  background-color: var(--bg-color-3);
  padding-left: 0.7rem;
}
.top_navbar_container > div {
  flex: 1;
}
.top_nav_item {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-left: 2rem;
}
.top_nav_item > span {
  transition: 500ms ease-in;
  border-bottom: 3px solid var(--bg-color-3);
  color: var(--white-color);
}
.top_nav_item > span:hover {
  color: var(--text-color-2);
  cursor: pointer;
  border-bottom: 3px solid var(--text-color-2);
}
.top_nav_item > span:active {
  transform: scale(1.1);
}
.selected_nav_item {
  color: var(--text-color-2) !important;
  border-bottom: 3px solid var(--text-color-2) !important;
}
.input_group > input {
  width: 100%;
  padding: 0.3rem 2rem;
  background-color: var(--input-color);
  border: none;
  color: var(--white-color);
  border-radius: 2rem;
  font-size: 1rem;
}
.input_group > input:focus {
  background-color: #3e404a; /* Change background color on focus */
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.164); /* Add a subtle box shadow on focus */
  outline: none; /* Remove the default focus outline */
}
.input_group > svg {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(-50%, -50%);
}
.input_group {
  position: relative;
  width: 60%;
}
.top_navbar_container > div:nth-child(3) {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-right: 2rem;
  align-items: center;
}
.hamburger_menu_icon {
  display: none;
}
.mobile_nav_header {
  background: var(--nav-color);
  color: var(--white-color) !important;
}
.top_mobile_nav_item {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}
/* media queries for mobile screen */
.toggle_btn {
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 4;
  position: absolute;
  top: 2px;
  left: 1px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}
.wallet_balance {
  display: inline-block;
  cursor: pointer;
}
.navbar_wrapper > a {
  margin-left: 0.5rem;
}
.disabled_btn {
  color: #3e404a !important;
  cursor: not-allowed !important;
}
@media screen and (min-width: 270px) and (max-width: 480px) {
  .top_navbar_container > div:nth-child(2) {
    display: none;
  }

  .hamburger_menu_icon {
    display: block;
  }
  .top_navbar_container > div:nth-child(3) {
    flex: 8;
  }
  .wallet_balance {
    display: none;
  }
  .navbar_wrapper > a {
    display: none;
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
  .wallet_balance {
    display: none;
  }
}

@media screen and (min-width: 480px) and (max-width: 1030px) {
  .top_navbar_container > div:nth-child(2) {
    display: none;
  }
  .top_navbar_container {
    padding: 0;
  }
  .navbar_wrapper > a {
    margin-left: 30px;
  }
  .hamburger_menu_icon {
    display: block;
  }
  .top_navbar_container > div:nth-child(3) {
    flex: 8;
  }
}
