.profile_container {
  width: 80%;
  margin: 0 auto;
}
.profile_header_box {
  display: flex;
  width: 70%;
  margin: auto;
  margin-top: 2rem;
  gap: 3rem;
}

.profile_box {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.profile_box > img {
  border-radius: 100%;
  height: 100px;
  aspect-ratio: 1;
  object-fit: cover;
}
.followers_container {
  display: flex;
  align-items: center;
  position: relative;
}
.followers_container > div {
  background: var(--nav-color);
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  border-radius: 1rem;
}
.followers_container > svg {
  position: absolute;
  right: 0;
  margin-bottom: 1rem;
  transform: translateX(80%);
}
.playlist_container {
  margin-top: 2rem;
}
.playlist_header {
  border-bottom: 2px solid var(--text-color-2);
}
.playlist_items {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid var(--text-color-2);
}
.playlist_items > div {
  font-size: 1.3rem;
  padding: 1rem 0;
}
.wallet_recharge > form,
.wallet_balance {
  margin-top: 1rem;
  line-height: 1.5rem;
}
.wallet_recharge > form > input {
  background: var(--text-color-2);
}
.helper_text {
  color: var(--text-color-2);
  font-size: 1rem;
}
.wallet_recharge > form > input {
  width: 100px;
  padding: 0.5rem;
  text-align: center;
  background-color: var(--input-color);
  border: none;
  color: var(--white-color);
  border-radius: 2rem;
  font-size: 1rem;
}
.wallet_recharge > form > input:focus {
  background-color: #3e404a; /* Change background color on focus */
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.164); /* Add a subtle box shadow on focus */
  outline: none; /* Remove the default focus outline */
}
.wallet_recharge h4 {
  margin-bottom: 1rem;
}
.wallet_recharge > form > button {
  padding: 0.4rem 1rem;
  margin-left: 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: 200ms ease-in;
  background: transparent;
}
.wallet_recharge > form > button {
  border: 1px solid var(--text-color-2);
  color: var(--text-color-2);
}
.wallet_recharge > form > button span::before {
  border: 5px solid var(--text-color-2);
}
.delete_account_btn {
  margin-top: 1rem;
}
.delete_account_btn > button {
  /* background: rgb(243, 76, 76); */
  background: var(--input-color);
  color: var(--white-color);
  float: right;
  padding: 0.4rem 1rem;
  /* margin-left: 1rem; */
  border-radius: 0.5rem;
  font-weight: 500;
  transition: 200ms ease-in;
  border: 1px solid var(--input-color);
}
.close_btn > button {
  background: var(--text-color-2);
  color: var(--white-color);
  padding: 0.4rem 1rem;
  margin-left: 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  margin-top: 1rem;
  transition: 200ms ease-in;
  border: 1px solid var(--text-color-2);
}
.price_modal_container {
  background: var(--bg-color-2);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;

  border-bottom: 0px;
}
.btn_group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.delete_acccountbox {
  padding: 2rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.profile_container {
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.2);
  width: 80%;
  /* margin: '; */
  /* background: red; */
  padding: 2rem;
  margin: auto;
  /* margin-top: 1rem; */
}
/* media queries for mobile screen */

@media screen and (min-width: 270px) and (max-width: 480px) {
  .profile_header_box {
    width: 100%;
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }
  .profile_box img {
    height: 80px;
  }
  .profile_box h3 {
    font-size: 1rem;
  }
  .profile_box h5 {
    font-size: 0.8rem;
  }
  .followers_container {
    display: inline-flex !important;
  }
  .followers_container > div {
    font-size: 1rem !important;
    padding: 0.4rem;
  }
  .followers_container > svg {
    height: 50px;
    transform: translateX(60%);
    margin-bottom: 1rem;
  }
  .profile_container {
    width: 90%;
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
}

@media screen and (min-width: 480px) and (max-width: 867px) {
}
