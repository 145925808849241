.carousel_container {
  width: 95%;
  margin: auto;
  /* margin-top: 2rem; */
}
.not_found_container > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.not_found_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
}
.carousel_container > h2 {
  font-size: 21px !important;
}
.not_found_container > h6 {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  color: var(--text-color-2);
}

.slick_prev:hover > svg > path {
  stroke: var(--white-color);
  /* color: #000; */
}
.slick_next:hover > svg > path {
  stroke: var(--white-color);
  /* color: #000; */
}
/* media queries for mobile screen */

@media screen and (min-width: 270px) and (max-width: 480px) {
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
}

@media screen and (min-width: 480px) and (max-width: 867px) {
}
