.details_header {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.details_header_container {
  width: 60%;
}
.details_header > img {
  height: 7rem;
  border-radius: 100%;
  object-fit: cover;
  aspect-ratio: 1;
}
.details_header > div > h1 {
  font-size: 2rem;
}
.details_header > div > div {
  gap: 1rem;
  display: flex;
}
.rating_box {
  display: flex;
  align-items: center;
  gap: 0.5rem !important;
}
.rating_box > img {
  height: 1rem;
  object-fit: contain;
}
.details_synopsis {
  margin-top: 1rem;
}
.cast_screens {
  display: flex;
  margin-top: 3rem;
  gap: 2rem;
}
.buy_tickets_container {
  width: 60%;
  padding: 3rem;
}
.buy_ticket_steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}
.buy_ticket_steps > div {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  flex: 1;
}
.buy_ticket_steps > div > div:nth-child(1) {
  border-radius: 100%;
  padding: 0rem 0.5rem;
  font-weight: 500;
  background-color: var(--white-color);
  color: black;
}
.line_for_steps {
  border: 1px dotted;
}
.selected_page {
  background-color: var(--text-color-2) !important;
  border: 1px dotted var(--text-color-2) !important;
}
.selected_screen {
  margin-top: 2rem;
}

@media screen and (min-width: 270px) and (max-width: 480px) {
  .details_header_container {
    width: 100%;
  }
  .details_header > img {
    height: 5rem;
  }
  .details_header > div > h1 {
    font-size: 1.5rem;
  }
  .details_header > div > div > h4 {
    font-size: 1rem;
  }
  .buy_ticket_steps {
    display: flex;
    width: 100%;
  }
  .buy_ticket_steps > div {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .buy_tickets_container {
    width: 100%;
    padding: 1rem;
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
}

@media screen and (min-width: 480px) and (max-width: 867px) {
}
