@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500;600&family=Playfair+Display:ital,wght@1,400;1,500&display=swap");
.nav_container {
  border: 1px solid rgba(138, 138, 138, 0);
  background: rgba(11, 24, 44, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1px 30px;
}
.nav_text > a {
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-size: 20px;
  cursor: pointer;
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  line-height: normal;
}
.nav_text:hover {
  color: #f6f1f1;
  font-weight: 500;
}
.nav_container > svg {
  height: 65px;
  width: 120px;
  margin-left: 35px;
  padding-top: 5px;
  object-fit: contain;
}
@media screen and (max-width: 600px) {
  .nav_container > svg {
    margin-left: 10px;
  }
}
