.layout_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: auto;
  flex-direction: column;
}

/* media queries for mobile screen */

@media screen and (min-width: 270px) and (max-width: 480px) {
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
}

@media screen and (min-width: 480px) and (max-width: 867px) {
}
