.detial_input {
    padding: 5px 15px;
    border: 2px solid white;
    border-radius: 5px;
    width: 100%;
    color: black;
}

.detial_input, textarea {
    padding: 5px 15px;
    border: 2px solid #3e404a; /* Set border color to #3e404a */
    border-radius: 5px;
    width: 100%;
    color: black;
    background-color: white; /* Set background color to #3e404a */
}

.detial_input:focus {
    border: 2px solid black;
    outline: none;
}

.Modalname {
    background-color: #111217 !important;
}


.detail_save_btn {
    border-radius: 20.5px;
    border: 2px solid rgba(220, 231, 245, 0);
    background: #1eddaf;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    padding: 5px 10px;
    line-height: normal;
}


.modal_header {
    background-color: #111217;
    color: white;
}

.launched_form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #111217;
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.modal_body {
    background-color: #111217;
}

.modal_fotter {
    background-color: #111217;
}

.createable_input {
    padding: 15px 15px;
    border: 2px solid #3e404a;
    border-radius: 5px;
    width: 100%;
    color: #111217;
}

.createable_input {
    padding: 0px;
}


.createable_input>div>div>div {
    border-radius: 10px;
    color: #3e404a;
    /* Set the placeholder text color */
    padding: 5px;
}

.createable_input>div>div>div:nth-last-child(1) {
    border: none;
}
.form_row {
    margin-bottom: 15px;
}

.input_container {
    display: flex;
    flex-direction: column;
}

.createable_input:focus {
    border: 2px solid #3e404a;
    /* Change border color on focus */
    outline: none;
    /* Remove default focus outline (optional) */
}



.css-13cymwt-control {
    
    background-color: #3e404a !important;
    border-color: #3e404a !important;
    border-radius: 4px;

}