@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500;600&family=Playfair+Display:ital,wght@1,400;1,500&display=swap");

.welcome_container {
  background-image: url("../../assets/new-images/home.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.welcome_box {
  background: linear-gradient(
    90deg,
    rgba(11, 27, 52, 0.8) 0%,
    rgba(11, 27, 52, 0) 100%
  );
  display: flex;
  padding: 200px 40px;
  align-items: center;
}
.welcome_box > div {
  flex: 1;
}

.welcome_text_box {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.welcome_box > div {
  flex: 1;
}
.welcome_text_box > div:nth-child(1) {
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.welcome_text_box > div:nth-child(1) span {
  color: #00c09f;
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.welcome_text_box > div:nth-child(2) {
  color: #fff;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 53px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 8px;
  align-items: center;
}

.welcome_text_box > div:nth-child(3) {
  color: #fff;
  font-family: "Playfair Display";
  font-size: 53px;
  font-style: italic;
  font-weight: 500;
  line-height: 61px;
}
.welcome_text_box > div:nth-child(4) {
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  margin-top: 14px;
  line-height: 51px;
}
.welcome_text_box > div:nth-child(2) span {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
}
.welcome_text_box > div:nth-child(2) span svg {
  position: absolute;
  bottom: 0;
  z-index: -1;
}
.signup_container {
  background: #013582;
  padding: 40px 10px;
}
.signup_text_box {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.signup_text_1 {
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.signup_text_2 {
  color: #b3ffb5;
  font-family: "Noto Sans", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.signup_text_box > div:nth-child(2) {
  position: relative;
}
.signup_text_box > div:nth-child(2) > svg:nth-child(1) {
  position: absolute;
  right: -15px;
  top: -10px;
}
.signup_text_box > div:nth-child(2) > svg:nth-last-child(1) {
  position: absolute;
  left: -5px;
  bottom: 0;
}
.signup_text_3 {
  color: #b3ffb5;
  font-family: "Noto Sans", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.signup_text_3 > span {
  position: relative;
  z-index: 1;
}
.signup_text_3 > span > svg {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.signup_text_4 {
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
}
.signup_btn_box {
  margin-top: 20px;
  text-align: center;
}
.signup_btn_box > button {
  border-radius: 6px;
  border: 1px solid #00c09f;
  background: #00c09f;
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  padding: 8px 15px;
  font-weight: 700;
  line-height: normal;
  margin: auto;
}
.ticket_container {
  background: #0d1626;
  display: flex;
  align-items: center;
}
.ticket_container > div {
  flex: 1;

  padding: 40px;
  text-align: center;
}
.ticket_container > div:nth-child(1) img {
  object-fit: contain;
  width: 100%;
}
.ticket_details_box {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
}
.ticket_details_box > div:nth-child(1) {
  color: #50e4ad;
  text-align: center;
  font-family: "Playfair Display";

  font-size: 58px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}
.ticket_details_box > div:nth-child(2) {
  color: #fff;
  text-align: center;
  font-family: "Noto Sans", sans-serif;

  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ticket_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ticket_box > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 50%;
  padding: 10px;
}
.ticket_box > div > div {
  color: #fff;
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.digital_container {
  background: #013582;
  display: flex;
  position: relative;
}
.digital_container > img {
  width: 100%;
}
.digital_container > img:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  object-fit: contain;
}
.digital_container > img:nth-last-child(1) {
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
  object-fit: contain;
}
.digital_container_details > img {
  object-fit: contain;
  width: 60%;
}
.digital_container_details {
  width: 100%;
  text-align: center;
  padding-top: 40px;
}
.digital_container_details > div:nth-child(1) {
  color: #fff;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 45px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}
.digital_container_details > div:nth-child(2) {
  color: #b3ffb5;
  font-family: "Noto Sans", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: 11.2px;
  text-transform: uppercase;
}
.digital_container_details > div:nth-last-child(2) {
  color: #fff;
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.explore_section {
  background: url(../../assets/new-images/explore.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.explore_section > div {
  display: flex;
  padding: 40px;

  background: linear-gradient(
    90deg,
    rgba(11, 27, 52, 0.8) 0%,
    rgba(11, 12, 52, 0) 100%
  );
}
.explore_section > div > div {
  flex: 1;
  text-align: center;
}
.explore_section_details {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.explore_section_details > div:nth-child(1) {
  color: #fff;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 35px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}
.explore_details {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.explore_details > div > div {
  color: #50e4ad;
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 4.8px;
  text-transform: uppercase;
}
.explore_section_details > div:nth-child(3) {
  color: #fff;
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.explore_btn_box > button {
  color: #fff;
  padding: 10px 15px;
  background: #00c09f;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #00c09f;
  background: #00c09f;
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.support_section {
  background: #0d1626;
  display: flex;
  padding: 20px;

  justify-content: center;
}
.support_section > div {
  flex: 1;
  margin: auto;
  padding: 20px;
  text-align: center;
}
.support_section_img > img:nth-child(1) {
  margin-top: 60px;
}
.support_section_details {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
}
.support_section_details > div:nth-child(1) {
  color: #03be9c;
  text-align: center;
  font-family: "Noto Sans", sans-serif;

  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.support_section_details > div:nth-child(2) {
  color: #03be9c;
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.support_section_details > div:nth-child(3) {
  color: #fff;
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.support_section_details > div:nth-child(4) button {
  border-radius: 6px;
  border: 1px solid #00c09f;
  background: #00c09f;
  color: #fff;
  margin-top: 20px;
  font-family: "Noto Sans", sans-serif;
  padding: 10px 15px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.footer_line {
  background: #0d1626;
  width: 100%;
}
/* media queries for tabs  */

@media screen and (min-width: 500px) and (max-width: 830px) {
  .welcome_box > div:nth-child(2) {
    display: none;
  }
  .welcome_text_box > div:nth-child(1) {
    font-size: 38px;
  }
  .welcome_text_box > div:nth-child(2) {
    font-size: 40px;
  }
  .welcome_text_box > div:nth-child(3) {
    font-size: 40px;
  }
  .welcome_text_box > div:nth-child(4) {
    font-size: 38px;
  }

  .signup_text_box {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    text-align: center; /* Center text */
    width: 100%; /* Take up the full width */
  }

  .signup_text_1 {
    font-size: 18px;
  }
  .signup_text_2 {
    font-size: 25px;
  }
  .signup_text_3 {
    font-size: 25px;
  }
  .signup_text_4 {
    font-size: 25px;
  }

  .signup_text_box > div:nth-child(2) {
    text-align: center;
  }
  .ticket_details_box > div:nth-child(1) {
    font-size: 35px;
  }
  .ticket_details_box > div:nth-child(2) {
    font-size: 24px;
  }
  .ticket_container > div {
    padding: 20px;
  }
  .digital_container_details > div:nth-child(1) {
    font-size: 25px;
  }
  .digital_container_details > div:nth-child(2) {
    font-size: 28px;
  }
  .support_section_details > div:nth-child(1) {
    font-size: 30px;
  }
  .support_section_details > div:nth-child(2) {
    font-size: 30px;
  }
  .support_section_details > div:nth-child(3) {
    font-size: 27px;
  }
  .support_section {
    padding: 10px;
  }
}

/* media queries for mobile devices */
@media screen and (min-width: 260px) and (max-width: 500px) {
  .welcome_text_box > div {
    text-align: center;
  }
  .welcome_box > div:nth-child(2) {
    display: none;
  }
  .welcome_text_box > div:nth-child(1) span {
    font-size: 30px;
  }
  .welcome_text_box > div:nth-child(1) {
    font-size: 30px;
  }
  .welcome_text_box > div:nth-child(2) {
    font-size: 32px;
  }
  .welcome_text_box > div:nth-child(3) {
    font-size: 32px;
  }
  .welcome_text_box > div:nth-child(4) {
    font-size: 30px;
  }
  .welcome_box {
    padding: 100px 20px;
  }
  .welcome_text_box > div:nth-child(4) {
    margin-top: 0px;
  }
  .signup_text_box {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    text-align: center; /* Center text */
    width: 100%; /* Take up the full width */
  }
  .signup_text_1 {
    font-size: 18px;
  }
  .signup_text_2 {
    font-size: 25px;
  }
  .signup_text_3 {
    font-size: 25px;
  }
  .signup_text_4 {
    font-size: 25px;
  }
  .signup_text_box > div:nth-child(2) {
    text-align: center;
  }
  .ticket_container {
    flex-direction: column;
    align-items: center;
  }
  .ticket_container > div {
    padding: 10px;
  }
  .ticket_details_box > div:nth-child(1) {
    font-size: 28px;
  }
  .ticket_details_box > div:nth-child(2) {
    font-size: 20px;
  }
  .ticket_box > div > div {
    font-size: 16px;
  }
  .digital_container_details > div:nth-child(1) {
    font-size: 16px;
  }
  .digital_container_details > div:nth-child(2) {
    font-size: 18px;
    letter-spacing: 7px;
  }
  .digital_container_details > div:nth-last-child(2) {
    font-size: 13px;
  }
  .digital_container > img:nth-last-child(1) {
    width: 30%;
  }
  .digital_container > img:nth-child(1) {
    width: 30%;
  }
  .digital_container_details > img {
    width: 100%;
  }
  .explore_section > div > div:nth-child(2) {
    display: none;
  }
  .explore_section_details {
    padding: 2px;
  }
  .explore_section_details > div:nth-child(1) {
    font-size: 25px;
  }
  .explore_details {
    width: 60%;

    gap: 10px;
  }
  .explore_details > div > div {
    font-size: 14px;
  }
  .explore_section > div {
    padding: 30px 10px;
  }
  .support_section {
    flex-direction: column;
  }
  .support_section_details > div:nth-child(1) {
    font-size: 30px;
  }
  .support_section_details > div:nth-child(2) {
    font-size: 30px;
  }
  .support_section_details > div:nth-child(3) {
    font-size: 27px;
  }
  .support_section_img > img {
    width: 100%;
  }
}
@media screen and (min-width: 1040px) and (max-width: 1500px) {
  .signup_text_1 {
    font-size: 20px;
  }
  .signup_text_2 {
    font-size: 25px;
  }
  .signup_text_3 {
    font-size: 25px;
  }
  .signup_text_4 {
    text-align: center;
    font-size: 25px;
  }
  .signup_text_box {
    width: 100%;
  }
}
@media screen and (min-width: 830px) and (max-width: 1040px) {
  .signup_text_box {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .signup_text_1 {
    font-size: 24px;
  }
  .signup_text_2 {
    font-size: 35px;
  }
  .signup_text_3 {
    font-size: 35px;
  }
  .signup_text_4 {
    text-align: center;
    font-size: 35px;
  }
}
