.slider_img_poster {
  object-fit: contain;
  width: 100%;
  padding: 0px 2px;
  transition: transform 0.3s ease-in;
  cursor: pointer;
  z-index: 10;

  position: relative;
}
.slider_img_poster:hover {
  /* transform: scale(1.3); */
  position: relative;
  z-index: 100001;
}
.slide-container:hover {
  transform: scale(1.1);
  z-index: 100930;
}
.slide-container {
  display: flex;
  margin: 10px 0px;
  flex-direction: column;
  transition: transform 0.3s ease-in;
  position: relative;
  z-index: 1002;
  justify-content: flex-start;
}

.slick-dots li.slick-active button:before {
  background: #efefef;
  height: 1px !important;
}
.slick-dots > li {
  background: #fff !important;
  height: 1px !important;
}
.slider_img_overlay {
  border-top: none;
}
.slick-prev:before,
.slick-next:before {
  opacity: 0.65 !important;
}

.silder_box {
  padding-top: 20px;
}
.slide-container:hover .slider_img_overlay {
  opacity: 1;
}
.slider_img_overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  line-height: 1rem;

  flex-direction: column;
  gap: 0px;
  justify-content: flex-end;
  background-image: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.8) 60%
  );
  color: #ffffff;
  cursor: pointer;
  z-index: 999449;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.slider_movie_price {
  position: absolute;
  z-index: 9944099;
  right: 0;
  margin: 10px 20px;
  padding: 2px 10px;
  font-size: 14px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.4);
}
.slider_img_overlay > p:nth-child(1) {
  text-overflow: ellipsis;
  width: 100%;
  padding-left: 10px;
  font-weight: 600;
  overflow: hidden; /* Add this line to hide overflowing text */
  white-space: nowrap; /* Add this line to prevent line breaks */
  font-size: 1.1rem;
  margin-bottom: 3px;
}
.slider_img_overlay > p:nth-child(2) {
  padding-left: 10px;
  font-size: 0.8rem;

  margin-bottom: 5px;
}
