.modal-body {
  position: absolute !important;
  height: 100vh;
  top: 0 !important;
  width: 100%;
  display: flex;
}
/* https://media4.giphy.com/media/KAGdnwyYOg3sB7SibL/giphy.gif?cid=ecf05e47pl5kbst2qhz7tna0vj6qsdfuzsnodon1kpcsj0jn&ep=v1_gifs_related&rid=giphy.gif&ct=g */
/* https://media3.giphy.com/media/PMV7yRpwGO5y9p3DBx/giphy.gif?cid=ecf05e47vnlo94hw48kdioq3tgxilpdwyydrfoxenitbbdi3&ep=v1_gifs_search&rid=giphy.gif&ct=g */
.modal-pop {
  width: 30%;
  border: 10px;
  z-index: 99;
  background-image: url("https://media3.giphy.com/media/PMV7yRpwGO5y9p3DBx/giphy.gif?cid=ecf05e47vnlo94hw48kdioq3tgxilpdwyydrfoxenitbbdi3&ep=v1_gifs_search&rid=giphy.gif&ct=g") !important;
  background-size: cover;

  margin: auto;

  vertical-align: middle;
  /* height: 400px; */
  padding: 10px 8px;
  border-radius: 20px !important;
  text-align: center;
}

.modal-pop > img {
  width: 80% !important;
  /* height: 300px !important; */
  object-fit: contain;
  margin: auto !important;
}

.modal-pop > p {
  text-align: center !important;

  margin: auto;
  font-weight: 500 !important;
}
.modal-span-text {
  color: #2188b5;
  font-weight: 600 !important;
}
.modal-btn {
  font-weight: 500;
  color: #fff;
  border-radius: 20px;
  border: none;
  padding: 2px 10px;
  margin-top: 15px;
  cursor: pointer;
  background-color: #1c6cbe;
}
.modal-btn:hover {
  background-color: #2188b5;
}
.modal-btn:active {
  background-color: #1c6cbe;
}

@media (max-width: 900px) {
  .modal-pop {
    width: 40% !important;
  }
}
@media (max-width: 500px) {
  .modal-pop {
    width: 70% !important;
  }
}
