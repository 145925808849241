.content_list_container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 1.5rem;
  gap: 1rem;
  flex-wrap: wrap;
}
.content_list_container > a {
  text-decoration: none;
}
.content_item {
  transition: 500ms ease-in-out;
}
.content_item:hover {
  box-shadow: rgba(255, 255, 255, 0.05) 0px 6px 24px 0px,
    rgba(255, 255, 255, 0.08) 0px 0px 0px 1px;
}
.content_item > div > img {
  width: 100%;
  object-fit: contain;
}
.content_item_header h5,
.content_item_header p {
  margin-bottom: 0;
}
.content_item_header {
  display: flex;
  color: var(--white-color);
  justify-content: space-between;
}
.genre_list {
  color: var(--white-color);
}
.img_container {
  position: relative;
  transition: 500ms ease-in-out;
}
.img_container > svg {
  fill: white;
  transition: 500ms ease-in-out;
}

.img_container > svg:hover {
  fill: var(--text-color-2);
}
.img_container > svg:hover rect {
  fill: var(--white-color);
}
.img_container > svg rect {
  fill: red;
  transform: 500ms ease-in;
}
.img_container > svg {
  position: absolute;
  z-index: 2;
  bottom: 0;
  cursor: pointer;

  right: 0;
  margin: 0.5rem;
}
/* media queries for mobile screen */

@media screen and (min-width: 270px) and (max-width: 480px) {
  .content_list_container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
  .content_list_container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 480px) and (max-width: 867px) {
}
