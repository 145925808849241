.content_list_container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 1.5rem;
  gap: 1rem;
}

.content_list_container > a {
  text-decoration: none;
}
.content_item_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  color: var(--white-color);
  font-size: 14px;
  font-weight: bold;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  /* overflow: hidden;
  max-width: 100%; */
}

.carousel_item_chip {
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.carousel_item_chip label {
  padding: 2px 10px;
  display: flex;
  flex-direction: row;
  background-color: #017bf4;
  border-radius: 15px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  color: var(--white-color);
  align-items: center;
  justify-content: center;
}

.poster_container {
  background-color: rgba(0, 0, 0, 0.1);
  height: 140px;
  /* width: 225px; */
  overflow: hidden;
  position: relative;
}

/* .content_item {
  transition: 500ms ease-in-out;
} */

.content_item {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 12px;
  overflow: hidden;
}

/* 
.content_item:hover {
  box-shadow: rgba(255, 255, 255, 0.05) 0px 6px 24px 0px,
    rgba(255, 255, 255, 0.08) 0px 0px 0px 1px;
} */
.content_item:hover {
  transform: scale(1.05);
  box-shadow: rgba(255, 255, 255, 0.1) 0px 6px 24px 0px,
    rgba(255, 255, 255, 0.15) 0px 0px 0px 1px;
}

.content_item > div > img {
  width: 100%;
  height: auto;
  min-height: 100px;
  max-height: 300px;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

/* Media Queries for Different Screen Sizes */

/* Mobile Screens */
@media screen and (max-width: 480px) {
  .content_list_container {
    grid-template-columns: repeat(2, 1fr);
  }
  .poster_container {
    min-height: 80px;
    max-height: 90px;
  }

  .content_item > div > img {
    height: inherit;
  }
}

/* Tablet Screens */
@media screen and (min-width: 480px) and (max-width: 867px) {
  .content_list_container {
    grid-template-columns: repeat(3, 1fr);
  }
  .poster_container {
    min-height: 100px;
    max-height: 200px;
  }

  .content_item > div > img {
    height: inherit;
  }
}

/* Medium Screens */
@media screen and (min-width: 867px) and (max-width: 1200px) {
  .content_list_container {
    grid-template-columns: repeat(4, 1fr);
  }
  .poster_container {
    min-height: 120px;
    max-height: 220px;
  }

  .content_item > div > img {
    height: inherit;
  }
}

/* Large Screens */
@media screen and (min-width: 1200px) {
  .content_list_container {
    grid-template-columns: repeat(5, 1fr);
  }
  .poster_container {
    min-height: 150px;
    max-height: 250px;
  }

  .content_item > div > img {
    min-height: 150px;
    max-height: 250px;
  }
}
