.banner_images {
  width: 100%;
  object-fit: contain;
  /* aspect-ratio: 2/1; */
  /* border-radius: 1rem; */
  height: 90vh;
  cursor: pointer;
}
.carousel_container {
  border-radius: 1rem;
  margin-top: 1rem;
}
.carousel_container_box {
  width: 100%;
  margin: auto;
}
.details_banner {
  /* border: 1px solid red; */
  display: flex;
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
}
.banner_box {
  position: relative;
}

/* media queries for mobile screen */

@media screen and (min-width: 270px) and (max-width: 480px) {
  .banner_images {
    width: 100%;
    object-fit: cover;
    border-radius: 1rem;
    height: 30vh;
  }
  .carousel_container_box {
    width: 95%;
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
}

@media screen and (min-width: 480px) and (max-width: 867px) {
}
