.referral-header {
  background-color: white;
}
.refer {
  padding-top: 5%;
}
.referal-header-box {
  padding: 1rem;
}

.refer-by-email {
  flex-direction: row;
  /* max-height: 11rem; */
  /* overflow: auto; */
}

.take-email {
  display: flex;
  align-items: center;
  border: 1px solid #3fa9f5;
  height: 33px;
  margin: 2px;
  border-radius: 20px;
  overflow: hidden;
  background: white;
}
.email-width {
  width: 40%;
}
.invite-btn {
  padding: 0px 20px;
  height: 35px;
  text-transform: none;
  background-color: #3fa9f5;
  font-size: medium;
}
.makeflex {
  display: flex;
  flex-direction: column;
}
.copybtn {
  padding-top: 3%;
  color: var(--text-color-2);
  font-weight: 500;
  align-items: start;
  cursor: pointer;
}
.refCodeInp {
  width: 100%;
  border: none;
  border-bottom: 1.5px solid grey;
  border-right: none;
  background-color: transparent;
  color: white;
}
.link-copy {
  flex-direction: row;
  justify-content: space-between;
}
.center {
  justify-content: center;
  align-items: center;
}
.bor {
  width: 90%;
  color: var(--text-color-2);
}
.cpy {
  border: 1.3px solid var(--text-color-2);
  width: 60%;
  padding: 1%;
  margin: 0 0 3%;
  border-radius: 8px;
}
.referraltable {
  border: 1.3px solid var(--text-color-2);
  width: 60%;
  padding: 3%;
  border-radius: 8px;
}
.referraltable > div > h3 {
  color: var(--text-color-2);
}
.underline {
  text-decoration: underline;
  font-size: medium;
}
.email-width > span {
  padding-left: 12px;
  color: rgb(32, 201, 151);
}

.heading-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 0px 0px 40px;
  background-color: var(--bg-color-2);
}
.heading-bar .head {
  display: flex;
  flex-direction: column;
}
.heading-bar .head h1 {
  color: white;
  font-size: 38px;
  font-weight: 600;
}
.heading-bar .head span {
  display: block;
  color: var(--text-color-2);
  font-weight: 400;
}

.round-btn {
  background-color: var(--text-color-2);
  font-size: 20px;
  color: #ffffff;
  border: 0px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 30px;
  padding: 10px 50px;
  text-align: center;
  margin: 0px auto;
  cursor: pointer;
}
.round-btn:hover,
.round-btn.enabled:hover,
.fill-btn:hover {
  /* background-color: var(--text-color-2); */
  background-color: transparent;

  color: var(--text-color-2);
  border: 1px solid var(--text-color-2);
}

.invite-btn {
  padding: 0px 20px;
  height: 35px;
  text-transform: none;
  /* background-color: #3fa9f5; */
  font-size: medium;
}

@media screen and (max-width: 820px) {
  .heading-bar {
    flex-direction: row;
    align-items: flex-start;
    margin: 0px 0px 10px;
  }
  .heading-bar .head h1 {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .cpy {
    width: 100%;
  }
  .table {
    width: 100%;
  }
  .email-width {
    width: 70%;
  }
  .invite-btn > span {
    display: none;
  }
  .invite-btn {
    padding: 0px 10px;
  }
  .heading-bar {
    flex-direction: column;
    align-items: flex-start;
    margin: 0px 0px 20px;
  }
  .heading-bar .head {
    margin: 0px 0px 10px;
  }
  .heading-bar .head h1 {
    font-size: 22px;
  }
  .round-btn {
    font-size: 18px;
    padding: 6px 20px;
  }
  .invite-btn > span {
    display: none;
  }
  .invite-btn {
    padding: 0px 10px;
  }
  .round-btn:hover,
  .round-btn.enabled:hover,
  .fill-btn:hover {
    background-color: #3fa9f5;
    color: white;
  }
}
@media (max-width: 600px) {
  .referraltable {
    width: 100%;
  }
  .heading-bar {
    flex-direction: column;
    align-items: flex-start;
    margin: 0px 0px 20px;
  }
  .heading-bar .head {
    margin: 0px 0px 10px;
  }
  .heading-bar .head h1 {
    font-size: 22px;
  }
}
