.login-block {
    margin-top: 5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    min-height: 100vh;
    z-index: 1;
    padding: 0 15px 20px;
    border-top: 2px solid darkgray;

}

.login-content {
    max-width: 450px;
    width: 100%;
}

.login-content .login-header {
    text-align: center;
    margin-bottom: 25px;
    padding-top: 10px;
}

.login-header .login-logo {
    display: block;
    /* margin-bottom: 40px; */
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
}

.login-logo img {
    max-width: 60%;
    height: auto;
    min-width: 250px;
}
.round-icon-bg{
    background-color: #3fa9f5;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 0px auto;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}
.round-icon-bg i{font-size: 20px; color: #ffffff;}

.login-form h2{text-align: center;}
.login-form .form-group {
    position: relative;
    z-index: 1;
    margin-bottom: 25px;
    text-align: center;
}

.login-form .form-group .form-control {
    min-height: 50px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #8385bf;
    padding: 10px 15px;
    background-color: transparent;
    /* color: #fff; */
    color: white !important;
}

.login-form .form-group .field-icon {
    position: absolute;
    z-index: 1;
    right: 19px;
    bottom: 18px;
    font-size: 14px;
    color: #bebebe;
}

.login-form .form-group .checkbox {
    padding-left: 5px;
    margin-right: 30px;
    margin-bottom: 5px;
}

.text-link{
    color: #0169d1 !important;
}

.term-conditions-text{
    color: white !important;
}

.sign-in-div{
    margin-top: 4rem;
}

.sign-in-div-msg{
    margin-top: 1.5rem;
}

.signin-form{
    width:65%;
    margin: auto;
}

.link{
    text-decoration: none;
    color: white;
}

.forgot-password:hover{opacity: 0.85;}

.login-form .form-group .text-link{color: white; font-size: 15px; font-weight: 500; text-decoration: none;}
.login-form .form-group .text-link:hover{color: #1e55bb;}
.round-btn{
    background-color: #1be4d3;
    font-size: 20px;
    color: #ffffff;
    border: 0px;
    text-transform:uppercase;
    font-weight: 500;
    border-radius: 30px;
    padding: 10px 50px;
    text-align: center;
    margin: 0px auto;
    cursor: pointer;
}
.fill-btn{
    background-color: #1be4d3;
    font-size: 20px;
    color: #ffffff;
    border: 0px;
    text-transform:uppercase;
    font-weight: 500;
    border-radius: 4px;
    padding: 10px 20px;
    text-align: center;
    width: 100%;
    cursor: pointer;
}
.round-btn:hover, .fill-btn:hover{background-color: #1e55bb;}


.login-content .login-footer {
    text-align: center;
    margin-bottom: 50px;
}

.login-content .login-footer p{
    color: #231f20;
    font-size: 15px;
}

.login-content .login-footer p .inline-text-link{color: #231f20; font-size: 15px; font-weight: 500; text-decoration: underline; cursor: pointer;}
p .inline-text-link:hover{color: #1e55bb;}

.form-control::placeholder {
    color: gray !important;
  }

  .term-conditions-text a:hover{
    opacity: 0.6;
  }

  .google-signin-input{
    border-radius: 25px !important;
  }

  .google-logo-img{
    width: 35px;
    margin-right: 34px;
  }

  .google-logo-link{
    text-decoration: none;
    color: white;
    font-weight: 600;
    border: none;
    background: none;
  }

  .google-signin-input button:hover{
    color: white !important;
    opacity: 0.8;
  }

  /* .common_box_body{
    color: green !important;
  } */

  /* .form-control:focus {
    color: white !important;
    background-color: black !important;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

input:-internal-autofill-selected{
    color: white !important;
    background-color: black !important;
} */

@media screen and (min-width:320px) and (max-width:600px) {
    .fill-btn, .round-btn{font-size: 18px; padding: 6px 10px;}
}

@media screen and (min-width:0px) and (max-width:319px) {
    .login-content .login-header{margin-bottom:20px;}
    .login-header .login-logo{margin-bottom:25px;}
    .login-form h2{font-size: 18px;}
    .round-icon-bg{width: 40px; height: 40px; margin: 10px auto;}
    .fill-btn, .round-btn{font-size: 15px; padding: 6px 10px;}
    .login-content .login-footer p, .login-content .login-footer p .inline-text-link, .login-form .form-group .text-link{font-size: 12px;}
}

@media screen and (max-width : 475px){
    .fill-btn{
        font-size: 16px;
        padding: 5px 10px;
        
    }
    .inline-text-link{
        display: block;
    }
    .common_box{
        border: 3px solid red;
    }
}

@media screen and (max-width : 350px){
    .fill-btn{
        font-size: 14px;
        padding: 5px 8px;
        
    }
    .login-logo{
        width: 250px;
    }
}