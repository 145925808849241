.carousel_container {
  position: relative;
  width: 100%;
}

.carousel_box {
  position: relative;
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 1;
  /* background: rgba(0, 0, 0, 0.5); */
  /* background-color: red; */
  border: 1px solid;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: 2s ease-in-out;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 25px;
}

.slick-prev:before,
.slick-next:before {
  content: "";
}

.not_found_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.not_found_container img {
  object-fit: contain;
  height: 200px;
}

/* media queries for mobile screen */
@media screen and (min-width: 270px) and (max-width: 480px) {
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
}

@media screen and (min-width: 481px) and (max-width: 800px) {
  .slick-slide {
    max-width: 380px;
  }
}

@media screen and (min-width: 801px) {
  .slick-slide {
    max-width: 250px;
  }
}
