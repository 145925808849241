.footer_box {
  background: #0d1626;
  /* mix-blend-mode: multiply; */
  padding-top: 40px;
  padding-bottom: 10px;
}
.footer_copyright_text {
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  padding: 9px;
  font-weight: 400;
  line-height: normal;
}
.footer_links_box {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-evenly;
}
.footer_links_box > div:nth-child(1) {
  flex: 1;
  text-align: center;
  vertical-align: middle;
}
.footer_socials {
  flex: 1;
}
.footer_links {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  flex: 2;
}
.footer_links > div > a {
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  line-height: normal;
}
.footer_socials > div:nth-child(1) {
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.footer_socials,
.footer_socials > div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.footer_socials > div svg:nth-child(1):hover {
  fill: #0d1626;
}
.carousel_box {
  width: 70%;
  margin: auto;
}
.carousel_slides > img {
  width: 70%;
  margin: auto;
  object-fit: contain;
}
.footer_carousel_text {
  color: #013582;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 40px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}
.footer_carousel {
  background: #e8fffb;
  padding-top: 30px;
  padding-bottom: 30px;
}
.footer_carousel > div:nth-child(1) > img {
  width: 40px;
  object-fit: contain;
}
.footer_carousel > div:nth-child(1) {
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 280px) and (max-width: 480px) {
  .footer_links {
    grid-template-columns: repeat(2, 1fr);
  }
  .footer_links_box {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 10px;
  }
}
