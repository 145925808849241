.signup_container {
  min-height: 100vh;
  /* background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom; */
  background: var(--bg-color-1);
}

.signup_box {
  width: 50%;
  margin: auto;
  text-align: center;
  padding-top: 160px;
}
.signup_img_box div {
  color: var(--white-color);

  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.signup_form_box {
  margin-top: 40px;
}
.signup_form_box > form {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  /* gap: 20px; */
  gap: 10px;
  width: 60%;
  /* max-width: 40%; */
  margin: auto;
}
.signup_form_box > form input {
  color: var(--text-color-1);
  width: 100%;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--white-color);

  line-height: normal;
}

/* Style the input element on focus */
.signup_form_box > form input:focus {
  border: 1px solid var(--btn-color-1); /* Change border color on focus */
  outline: none; /* Remove default focus outline (optional) */
}

/* Style the placeholder text */
.signup_form_box > form input::placeholder {
  color: var(--text-color-1); /* Set the placeholder text color */
}
.err_message_pass {
  font-size: 14px;
  margin-top: 0px;
  color: red;
  margin-bottom: 0px;
}
.signup_form_box > form > .signup_btn {
  width: 100%;
}
.signup_form_box > form > .signup_btn > button {
  width: 100%;
  background-color: var(--btn-color-1);
  color: var(--white-color);
  border-radius: 10px;

  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  padding: 10px 15px;
  line-height: normal;
  border: none;
  /* margin-top: 30px; */
}

.continue_with_google {
  margin-top: 20px;
}

.continue_with_google > button {
  border: none;
  color: var(--text-color-1);

  background: var(--white-color);
  font-size: 17px;
  font-style: normal;
  border-radius: 10px;
  padding: 10px 25px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 10px;
  margin: auto;
}
.continue_with_google > div {
  color: var(--white-color);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  line-height: normal;
}
.continue_with_google > div > span {
  color: var(--text-color-1);
  font-weight: 500;
  cursor: pointer;
}

.input_group {
  position: relative;
  width: 100%;
}
.input_group_password_icon {
  position: absolute;
  right: 10px;
  top: 50%; /* Center the icon vertically */
  transform: translateY(-50%);
  text-decoration: none;
  color: var(--text-color-2);
}
.forgot_container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.forgot_pass {
  cursor: pointer;
}

/* media queries for mobile screen */

@media screen and (min-width: 270px) and (max-width: 480px) {
  .signup_box {
    width: 95%;
  }
  .signup_container {
    width: 100%;
  }
  .signup_form_box > form input {
    font-size: 15px;
  }
  .signup_form_box > form {
    min-width: 90%;
  }
}

/* media queries for tabs screen */

@media screen and (min-width: 480px) and (max-width: 620px) {
}

@media screen and (min-width: 480px) and (max-width: 867px) {
}
