.dropdown-menu.show {
  background: var(--nav-color) !important;
  color: var(--white-color) !important;
}
.dropdown-item {
  color: var(--white-color) !important;
}
.dropdown-item:hover {
  color: var(--nav-color) !important;
}
