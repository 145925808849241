.playlist_container {
  margin-top: 1rem;
}
.playlist_btn {
  color: var(--white-color);
  border: none;
  background-color: var(--disabled-btn);
  padding: 0.5rem 1rem;
  gap: 1rem;
  display: flex;
  border-radius: 0.5rem;
}
.creators_folow_box {
  margin-top: 1rem;
}
.creators_folow_box > div {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.resume_btn_box {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
}
.follow_btn,
.resume_btn {
  border: 1px solid var(--text-color-2);
  color: var(--text-color-2);
  background: transparent;
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
}
.resume_btn {
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 1.3rem;
}
.resume_btn_box > button:nth-child(2) {
  color: var(--text-color-2);
  color: var(--text-color-2);
  background: transparent;
  border: none;
  padding: 0.1rem 0.5rem;
  font-size: 1.3rem;
}
.follow_btn:hover,
.resume_btn:hover {
  color: var(--white-color);
  border: 1px solid;
  transition: 200ms ease-in;
}
.resume_btn_box > button:nth-child(2):hover {
  color: var(--white-color);

  transition: 200ms ease-in;
}
.resume_btn_box > button:nth-child(2):active {
  color: var(--text-color-2);
}
.follow_btn:active,
.resume_btn:active {
  border: 1px solid var(--text-color-2);
  color: var(--text-color-2);
}
