@import url("https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap");

.pre_launch_container {
  height: 80vh;
  display: flex;
  background-size: contain;
}
.pre_launch_details {
  flex: 1;
  padding: 2rem 0px 0px 1rem;
}

.pre_launch_img {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pre_launch_img > img {
  width: 90%;
  margin: auto;
  object-fit: contain;
}
.pre_launch_text {
  display: flex;
  flex-direction: column;
  font-family: "Fugaz One", cursive;
  align-items: flex-start;
  line-height: 0.5rem;
}
.pre_launch_text > h1 {
  font-size: 40px;
}
.pre_launch_text > h1:nth-child(2) {
  font-size: 50px;
}
.pre_launch_points {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}
.pre_launch_points > div {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}

.pre_launch_points > div > p {
  text-align: start;
  font-size: 15px;
}

/* media queries for mobile screens  */

@media screen and (min-width: 0px) and (max-width: 429px) {
  .pre_launch_container {
    flex-direction: column-reverse;
    padding: 1rem;
    height: 100%;
    background-image: url(../../images/mobilebg.png);
    background-repeat: no-repeat;
    background-position-y: top;
    background-position-x: center;
    background-size: cover;
  }
  .pre_launch_img {
    display: none;
  }
  .pre_launch_points {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 2rem;
  }
  .pre_launch_details {
    padding: 0px;
  }
  .pre_launch_text > h1 {
    font-size: 30px;
  }
  .pre_launch_text > h1:nth-child(2) {
    font-size: 30px;
  }
  .pre_launch_points > div > p {
    font-size: 13px;
  }
}

@media screen and (min-width: 320px) and (max-width: 630px) {
  .pre_launch_container {
    flex-direction: column-reverse;
    height: 100%;
    background-image: url(../../images/mobilebg.png);
    background-repeat: no-repeat;
    background-position-y: top;
    background-position-x: center;
    background-size: cover;
  }
  .pre_launch_img {
    display: none;
  }
  .pre_launch_points {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 2rem;
  }
  .pre_launch_details {
    padding: 0px;
  }
  .pre_launch_text > h1 {
    font-size: 30px;
  }
  .pre_launch_text > h1:nth-child(2) {
    font-size: 30px;
  }
}

/* media queries for tab-screens  */

@media screen and (min-width: 630px) and (max-width: 1200px) {
  .pre_launch_img {
    display: none;
  }
  .pre_launch_container {
    flex-direction: column-reverse;
    height: 100%;
    background-image: url(../../images/desktopbg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
