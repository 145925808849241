.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000); */
}

.showMoreButton{
 margin-top: 1rem;
  display: flex;
  border: 2px solid var(--text-color-2);
  font-weight: 500;
  font-size: 1.3rem;
  width: fit-content;
  color: var(--text-color-2);
  align-items: flex-start;
  background-color: transparent;
  border-radius: 1rem;
  transition: 200ms ease-in;
}

.showMoreButtonContainer{
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
}


@media screen and (min-width: 270px) and (max-width: 620px) {
  .container{
    gap: 5px;
  }
}
