.header_text {
  text-decoration: underline;
  font-weight: 500;
  text-align: center;
}

.privacy_container {
  background-color: #fff;
  color: #000;
  padding: 30px;
}

.privacy_container li {
  margin-bottom: 10px;
}
