.transparent-background {
  position: relative !important;
}

.transparent-modal {
  overflow-y: hidden;
  /* opacity: 0.5; */
  /* filter: blur(10px) !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  ); */
  height: 100vh !important;
}
.background-opacity {
  width: 100% !important;
  height: 100vh;
  top: 0;
  position: absolute;
  opacity: 0.6;
  background: #000;
}
.form_modal_box {
  background: #000;
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0;
}
.otp-form-body {
  background: #000 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 5px;
}

.otp-form-body input {
  width: 100%;
  padding: 8px 5px;
  background-color: #28282d;
  color: #fff;
  border: none;
  border-radius: 10px;
}
.otp-form-body input:focus {
  outline: none;
  background-color: #28282d;
}

.otp-form-body .otp-err-message {
  text-align: left;
  color: rgb(255, 131, 131);
}
.otp-form-box input {
  text-align: center;
}
.otp-form-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
/* .otp-form-heading {
  line-height: 0;
} 
*/
.otp-form-box .wrong-input-message {
  border: 2px solid rgb(255, 131, 131) !important;
}
