.browse_container {
  background: var(--bg-color-3);
}
.comming_soon_container {
  margin: auto;
  width: 100%;
}
.comming_soon_container > img {
  width: 100%;
  object-fit: contain;
  max-height: 500px;
}

.follow_btn {
  border: 1px solid var(--text-color-2);
  color: var(--white-color);
  background: var(--text-color-2);
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
  display: inline-flex;
}

.follow_btn:hover {
  color: var(--text-color-2);
  border: 1px solid;
  background: transparent;
  transition: 200ms ease-in;
}
.follow_btn:active {
  border: 1px solid var(--text-color-2);
  color: var(--white-color);

  background: var(--text-color-2);
}

.coming_soon_modal {
  background: var(--bg-color-2);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.3rem;
  border-bottom: 0px;
}
