.add_buddy_container {
    display: flex;
    align-items: flex-start;
  }
  .add_buddy_container > div:nth-child(1) {
    flex: 2;
  }
  .add_buddy_container > div:nth-child(2) {
    flex: 3;
  }
  .scaner_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .scaner_container > h4,
  .viewer_header > h4 {
    color: var(--text-color-2);
  }
  .scaner_container > img {
    object-fit: contain;
    width: 100%;
    height: 250px;
  }
  .social_container {
    margin-top: 1rem;
    font-size: 1.2rem;
    width: 100%;
  }
  .social_container > input {
    width: 100%;
    background: transparent;
    outline: none;
    border: 1px solid var(--text-color-2);
    color: var(--white-color);
    padding: 0.1rem 1rem;
    margin-top: 0.4rem;
    border-radius: 0.4rem;
  }
  .viewer_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .viewer_header > button {
    font-weight: 500;
    background: var(--text-color-2);
    padding: 0.1rem 1rem;
    border-radius: 1rem;
    border: 1px solid var(--text-color-2);
  }
  .viewer_header > button:hover {
    background-color: #79dcb8;
  }
  .viewer_list > div {
    display: flex;
    border-radius: 1rem;
  }
  .viewer_list > div:nth-child(1) > div:nth-child(1) {
    border-top-left-radius: 1rem;
  }
  .viewer_list > div:nth-child(1) > div:nth-last-child(1) {
    border-top-right-radius: 1rem;
  }
  
  .viewer_list > div:nth-last-child(1) > div:nth-child(1) {
    border-bottom-left-radius: 1rem;
  }
  .viewer_list > div:nth-last-child(1) > div:nth-last-child(1) {
    border-bottom-right-radius: 1rem;
  }
  .viewer_list > div > div {
    border: 1px solid var(--text-color-2);
    flex: 1;
    padding: 0.1rem 1rem;
  }
  .viewer_list {
    width: 100%;
    margin-top: 1rem;
    border: 1px solid var(--text-color-2);
    border-radius: 1rem;
  }
  .price_seen_container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .payment_btn > button {
    padding: 0.3rem 1rem;
    border-radius: 10px;
    font-weight: 500;
  }
  
  .payment_btn > button:nth-child(1) {
    background: var(--text-color-2);
    border: 1px solid var(--text-color-2);
  }
  
  .payment_btn > button:nth-child(2) {
    color: var(--text-color-2);
    background: transparent;
    margin-left: 1rem;
    border: 1px solid var(--text-color-2);
  }
  .helper_text {
    font-size: 0.8rem;
    margin-top: 1rem;
  }
  