/* Remove the spin buttons for number input */
.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no-spin {
  -moz-appearance: textfield;
}

/* Disable mouse wheel for number input */
 /* .no-scroll {
  pointer-events: none; 
} */
